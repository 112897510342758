import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import LearnSectionWrapper from "./LearnSectionWrapper";
import StyledPromoBanner from "./StyledPromoBanner";
import ArrowRight from "../../globals/icons/ArrowRight";

import { Image } from "../../globals/UIKit";
import { StyledSubTitle2, StyledDesc2, StyledDescTag } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { removePaddTop } from "../shared/CommonStyled";
import { isBrowser } from "../../utils/constants";

import hasuraFree from "./images/hasura-free.png";
// import traingleArrow from "./images/traingle-arrow.svg";
import star from "./images/star.svg";

const LearnSectionWrapperRemoveBor = styled(LearnSectionWrapper)`
  border-bottom: 0;
  padding-bottom: 0;
`;

const StyledPromoBannerImg = styled(StyledPromoBanner)`
  background-image: ${props =>
    props.isVisible
      ? "url(https://res.cloudinary.com/dh8fp23nd/image/upload/v1672906143/main-web/hasura-free-ff60e409244e0ea12b5a3045d1a9096b_lirv3y.png)"
      : ""};

  background-color: ${COLORS.grey_10};
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 20%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  ${mq.below.md} {
    background-image: none;
  }

  &:hover {
    box-shadow: 0px 14px 60px 0px rgb(0 0 0 / 16%);

    .arrow {
      transform: translateX(2px);
    }
  }

  .try-hasura-div {
    display: flex;

    .arrow {
      margin-left: 4px;
      margin-bottom: -3px;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const UseHasuraFree = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hasuraIllusRef = useRef();

  useEffect(() => {
    if (isBrowser) {
      if (!hasuraIllusRef?.current) {
        return;
      }

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });

      observer.observe(hasuraIllusRef.current);
    }
  }, [hasuraIllusRef]);

  return (
    <a href="https://cloud.hasura.io/signup?pg=learn&plcmt=body&cta=try-graphql-with-hasura&tech=default">
      <LearnSectionWrapperRemoveBor css={removePaddTop}>
        <StyledPromoBannerImg isVisible={isVisible || false}>
          <div className="p40" ref={hasuraIllusRef}>
            <StyledSubTitle2 paddingBottom="pb16">
              Start with GraphQL on Hasura for Free
            </StyledSubTitle2>
            <StyledDesc2 paddingBottom="pb24">
              <ul>
                <li>
                  <Image className="leftIcon" src={star} alt="Arrow" />
                  Build apps and APIs 10x faster
                </li>
                <li>
                  <Image className="leftIcon" src={star} alt="Arrow" />
                  Built-in authorization and caching
                </li>
                <li>
                  <Image className="leftIcon" src={star} alt="Arrow" />
                  8x more performant than hand-rolled APIs
                </li>
              </ul>
            </StyledDesc2>
            <StyledDescTag className="contributeLink" linkVariant="grey_100" fontWeight="font_600">
              <div className="try-hasura-div">
                Try GraphQL with Hasura
                <div className="arrow">
                  <ArrowRight variant="grey_100" size="xs" className="arrow" />
                </div>
              </div>
            </StyledDescTag>
          </div>
          <div className="promoImgWrapper">
            <Image className="showMobile" src={hasuraFree} alt="Promo" />
          </div>
        </StyledPromoBannerImg>
      </LearnSectionWrapperRemoveBor>
    </a>
  );
};

export default UseHasuraFree;

import styled from "styled-components";
import { mq } from "../../globals/utils";
import {COLORS} from "../../globals/designSystem";

export const LearnSectionWrapper = styled.section`
  padding: 80px 0;
  border-bottom: 1px solid ${COLORS.grey_20};
  ${mq.between("md", "lg")} {
    padding: 64px 0;
  }
  ${mq.below.md} {
    padding: 48px 0;
  }
`;

export default LearnSectionWrapper;

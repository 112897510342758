import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { StyledTitleNew1, StyledTitleNew4, StyledBody2 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import soc from "./images/soc.png";
import hipaa from "./images/hipaa.png";
import privacyShield from "./images/privacy-shield.svg";
import gdpr from "./images/gdpr.png";
import iso from "./images/iso.png";

const featureState = [
  {
    imgSrc: soc,
    title: "Hasura Cloud is SOC 2 Type II Compliant",
    desc:
      "To achieve our SOC 2 Type II certification, we have implemented an exhaustive list of security controls including technical safeguards like penetration testing by an independent security firm, vulnerability scans and encryption, and process measures such as security training and disaster recovery planning. The audit report is available on request, under an NDA.",
  },
  {
    imgSrc: hipaa,
    title: "Hasura Cloud is HIPAA Compliant",
    desc:
      "Hasura Cloud’s HIPAA compliance has been audited by an independent third party, and includes controls just as stringent as that of healthcare organizations to protect the privacy, security, and integrity of protected health information (PHI). As part of Hasura Cloud’s Enterprise plan, we can also sign Business Associate Agreements (BAA) with our customers as needed.",
  },
  {
    imgSrc: privacyShield,
    title: "Hasura is Regularly Penetration Tested",
    desc:
      "We regularly undergo penetration testing by an independent security firm as part of our SOC 2 process and can provide findings to customers as required under NDA.",
  },
  {
    imgSrc: gdpr,
    title: "GDPR compliant",
    desc: (
      <span>
        Our offerings are fully GDPR compliant. Read our{" "}
        <Link to="/legal/hasura-privacy-policy/">Privacy Policy</Link>.<br />
        We are happy to arrange custom DPAs where required.
        <br />
      </span>
    ),
  },
  {
    imgSrc: iso,
    title: "Hasura is ISO certified",
    desc:
      "Hasura, Inc operates an Information Security Management System that conforms to the requirements stated in the standard: ISO/IEC 27001:2013 supporting the Hasura GraphQL Engine. We achieved multi-site certification, covering our primary office locations in San Francisco, United States and Bangalore, India.",
  },
];

const StyledFeatureWrapper = styled.div`
  .mWidth500 {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }
  .securityFeatureListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    padding-top: 40px;
    .securityFeatureList {
      background: ${COLORS.white};
      box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 40px;
      .securityFeatureImg {
        padding-bottom: 16px;
        img {
          max-height: 80px;
        }
      }
    }
  }
  ${mq.between("md", "lg")} {
    .securityFeatureListWrapper {
      .securityFeatureList {
        padding: 24px;
      }
    }
  }
  ${mq.below.md} {
    .securityFeatureListWrapper {
      grid-template-columns: 1fr;
      .securityFeatureList {
        padding: 24px;
      }
    }
  }
`;

const SecurityFeature = () => {
  const featureWrapper = featureState.map((feature, index) => {
    return (
      <div key={index} className="securityFeatureList">
        <div className="securityFeatureImg">
          <img src={feature.imgSrc} alt={feature.title} />
        </div>
        <StyledTitleNew4 paddingBottom="pb16" fontWeight="font_600">{feature.title}</StyledTitleNew4>
        <StyledBody2>{feature.desc}</StyledBody2>
      </div>
    );
  });
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledFeatureWrapper>
          <StyledTitleNew1 className="mWidth500">Hasura undergoes rigorous compliance audits</StyledTitleNew1>
          <div className="securityFeatureListWrapper">{featureWrapper}</div>
        </StyledFeatureWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default SecurityFeature;

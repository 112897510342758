import styled from "styled-components";

export const StyledFeatureWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  /* grid-template-columns: 1fr 1fr; */
  grid-gap: 120px;
  clear: both;
  .positionStickey {
    position: sticky;
    top: 110px;
    align-self: flex-start;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 90px;
  }
  @media(max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    .positionStickey {
      position: static;
    }
  }
`;

export default StyledFeatureWrapper;

import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TopBanner from "../components/security/topbanner";
import SecurityAuthenticationAuthorization from "../components/security/securityauthenticationauthorization";
import SecurityFeature from "../components/security/securityfeature";
import UseHasuraFree from "../components/learn/UseHasuraFree";
import StyledContainerWrapper from "../components/shared/StyledContainerWrapper";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/card.png",
};
const canonicalUrl = "https://hasura.io/security/";
const Security = props => (
  <Layout location={props.location}>
    <Seo
      title="Enterprise Grade Security | Hasura Cloud"
      description="Hasura Cloud undergoes 3 layers of security scrutiny & is architected to follow the 3 tenets of Information Security: confidentiality, integrity & availability."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner location={props.location} />
    <SecurityAuthenticationAuthorization />
    <SecurityFeature />
    <StyledContainerWrapper>
      <UseHasuraFree />
    </StyledContainerWrapper>
  </Layout>
);

export default Security;

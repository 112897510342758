import styled from "styled-components";

export const StyledTabPanelWrapper = styled.section`
  padding-top: 100px;
  &:first-child {
    /* padding-top: 0; */
  }
  .removePaddBottom {
    padding-bottom: 0 !important
  }
  .fontBold {
    font-weight: 600;
  }
  .pb8 {
    padding-bottom: 8px;
  }
  .pt16 {
    padding-top: 16px;
  }
  .pb16 {
    padding-bottom: 16px;
  }
  .pb24 {
    padding-bottom: 24px;
  }
  h3 {
    font-size: 18px;
  }
  .panelIcon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    margin-bottom: 16px;
    img {
      width: 24px;
    }
  }
  .tabPanelImg {
    img {
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
      width: 100%;
      border-radius: 4px;
    }
  }
  .tabPanelVideo {
    padding-top: 40px;
    width: 100%;
  }
  .tabPanelImgAddBott {
    padding-bottom: 48px;
  }
  .tabPanelMultipleImgWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px 8px;
    padding-top: 32px;
    .tabPanelMultipleImgList {
      .tabPanelMultipleImg {
        padding-bottom: 8px;
        img {
          width: 100%;
        }
      }
      .p12 {
        padding: 0 12px;
      }
    }
  }
  .tabPanelMultipleColumnImgWrapper {
    grid-template-columns: 1fr;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .tabPanelImg {
      img {
        min-width: unset;
        max-width: none;
        width: 100%;
      }
    }
  }
  @media(max-width: 767px) {
    padding-top: 50px;
    .tabPanelImg {
      img {
        min-width: unset;
        max-width: none;
        width: 100%;
      }
    }
  }
`;

export default StyledTabPanelWrapper;

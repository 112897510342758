import React, { useRef } from "react";

import Features from "../database/sqlServer/Features";

import confidentiality from "./images/confidentiality.svg";
import integrity from "./images/integrity.svg";
import availability from "./images/availability.svg";

const securityListState = [
  {
    id: "confidentiality",
    imgSrc: confidentiality,
    linkContent: "Inforsec tenet one: Confidentiality",
    title: "Confidentiality",
    label: "Inforsec tenet one:",
    desc: <div className="pt16">
      <span className="fontBold pb8">Allow Lists</span><br/>
      Define a list of queries and mutations that are approved and execution will be limited to that list. With allow lists, you can limit the queries your users are able to execute.<br/><br/>
      <span className="fontBold pb8">Optional Introspection</span><br/>
      Disable schema introspection to add an extra layer of obscurity and data structure opaqueness for unauthorized individuals.<br/><br/>
      <span className="fontBold pb8">Security Event Logging</span><br/>
      Hasura Cloud maintains meticulous logs for security events and does threat-assessment pattern mapping to look for anomalies.<br/><br/>
      <span className="fontBold pb8">Access Controls</span><br/>
      Provide fine-tuned access controls to ensure the person requesting data is only able to fetch/mutate content they’ve been given permission to access.<br/><br/>
      <span className="fontBold pb8">Single Tenant Mode, VPC Peering</span><br/>
      Hasura Cloud enables a wide range of hosting options covering all possible use-cases, including a hosted, single-tenant mode.
    </div>,
  },
  {
    id: "intigrity",
    imgSrc: integrity,
    linkContent: "Inforsec tenet two: Integrity",
    title: "Integrity",
    label: "Inforsec tenet two:",
    desc: <div className="pt16">
      <span className="fontBold pb8">Migration API</span><br/>
      The Hasura Cloud migration API ensures that all changes to the Hasura Cloud metadata are observable, recoverable, and enforce eventual consistency.<br/><br/>
      <span className="fontBold pb8">RBAC Controls</span><br/>
      User accounts can be highly scoped based on need-to-access and need-to-know params, ensuring users do not manipulate content or settings outside of their area of responsibility. This includes the ability to limit to analytics only account access, read-only mode, and more.<br/><br/>
      <span className="fontBold pb8">SSO Access</span><br/>
      Hasura Cloud supports modern SSO access patterns, enabling centralized user management.<br/><br/>
      <span className="fontBold pb8">Multi-Layer Input Validation</span><br/>
      It’s no surprise that SQL injection attacks continue to be attack-vector number one for those meaning harm. Hasura Cloud has vigilant processes in places to mitigate this.
    </div>,
  },
  {
    id: "availability",
    imgSrc: availability,
    linkContent: "Inforsec tenet three: Availability",
    title: "Availability",
    label: "Inforsec tenet three:",
    desc: <div className="pt16">
      <span className="fontBold pb8">Rate Limiting</span><br/>
      Hasura Cloud can limit access to any of our managed clusters to ensure balanced and consistent availability for all our customers.<br/><br/>
      <span className="fontBold pb8">DDOS Monitoring</span><br/>
      Hasura Cloud actively monitors for abnormal traffic spikes and has tools in place to mitigate the effects of bad actors.<br/><br/>
      <span className="fontBold pb8">Single Tenant Mode, VPC</span><br/>
      Higher availability by reduction of noisy-neighbour effects.<br/><br/>
      <span className="fontBold pb8">Cloud Scale</span><br/>
      Hasura Cloud is optimized for growth and is able to scale with the most demanding of customers.
    </div>,
  },
]


const SecurityAuthenticationAuthorization = () => {
  const confidentialityRef = useRef(null);
  const intigrityRef = useRef(null);
  const availabilityRef = useRef(null);

  const securitySection = {
    "confidentiality": { section: "confidentiality", ref: confidentialityRef },
    "intigrity": { section: "intigrity", ref: intigrityRef },
    "availability": { section: "availability", ref: availabilityRef },
  }

  return (
    <Features
      title="Security is more than just authentication and authorization"
      sections = {securitySection}
      tabListState = {securityListState}
    />
  );
};

export default SecurityAuthenticationAuthorization;

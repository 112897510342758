import styled from "styled-components";

export const StyledTabListWrapper = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 32px;
  .tabList {
    padding: 10px 12px;
    border-radius: 4px;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #F3F8FB;
    }
    img {
      margin-right: 16px;
      width: 24px;
    }
  }
  .tabListActive {
    background-color: #F3F8FB;
  }
`;

export default StyledTabListWrapper;

import React, { useState, useRef, useMemo, useEffect } from "react";
import styled from "styled-components";

import { COLORS } from "../../../globals/designSystem";
import { Box } from "../../../globals/UIKit";
import {
  StyledSubTitle1,
  StyledDesc1,
  StyledDesc2,
  StyledDesc3,
  StyledSubTitle2,
} from "../../shared/StyledTypography";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";

import StyledFeatureWrapper from "../../shared/StyledFeatureWrapper";
import StyledTabListWrapper from "../../shared/StyledTabListWrapper";
import StyledTabPanelWrapper from "../../shared/StyledTabPanelWrapper";
import { mq } from "../../../globals/utils";

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
  padding: 120px 0;
  ${mq.between("md", "lg")} {
    padding: 70px 0;
  }
  ${mq.below.md} {
    padding: 50px 0;
  }
`;

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const Features = ({ sections, tabListState, title, desc }) => {
  const [visibleSection, setVisibleSection] = useState();

  const headerRef = useRef(null);

  const sectionRefs = useMemo(() => sections, [sections]);

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = Object.values(sectionRefs).find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        } else {
          return null;
        }
      });
      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection, sectionRefs]);

  const tab = tabListState.map((listItem, index) => {
    return (
      <div
        key={index}
        className={"tabList" + (visibleSection === listItem.id ? " tabListActive" : "")}
        role="presentation"
        onClick={() => {
          scrollTo(sectionRefs[listItem.id].ref.current);
        }}
      >
        <img src={listItem.imgSrc} alt={listItem.linkContent} />
        <StyledDesc3>{listItem.linkContent}</StyledDesc3>
      </div>
    );
  });
  const tabPanel = tabListState.map((listItem, index) => {
    return (
      <StyledTabPanelWrapper key={index} id={listItem.id} ref={sectionRefs[listItem.id].ref}>
        <div className="panelIcon">
          <img src={listItem.imgSrc} alt={listItem.linkContent} />
        </div>
        {listItem.label ? <StyledDesc2 variant="purple">{listItem.label}</StyledDesc2> : null}

        <StyledSubTitle2 paddingBottom="pb8" as={listItem.atributeas ? listItem.atributeas : null}>
          {listItem.title}
        </StyledSubTitle2>
        {listItem.subTitle ? (
          <StyledDesc1 fontWeight="font_bold">{listItem.subTitle}</StyledDesc1>
        ) : null}
        {listItem.desc ? (
          <StyledDesc1 paddingBottom={listItem.panelImg ? "pb40" : "pb0"}>
            {listItem.desc}
          </StyledDesc1>
        ) : null}
        {listItem.panelImg ? (
          <div className={"tabPanelImg" + (listItem.subTitle2 ? " tabPanelImgAddBott" : "")}>
            <img loading="lazy" src={listItem.panelImg} alt={listItem.title} />
          </div>
        ) : null}
        {listItem.panelVideo ? (
          <video controls={false} autoPlay muted loop className="tabPanelVideo">
            <source src={listItem.panelVideo} type="video/mp4" />
          </video>
        ) : null}
        {listItem.subTitle2 ? (
          <StyledDesc1 fontWeight="font_bold">{listItem.subTitle2}</StyledDesc1>
        ) : null}
        {listItem.desc2 ? (
          <StyledDesc1 paddingBottom={listItem.panelImg2 ? "pb40" : "pb0"}>
            {listItem.desc2}
          </StyledDesc1>
        ) : null}
        {listItem.panelImg2 ? (
          <div className="tabPanelImg">
            <img loading="lazy" src={listItem.panelImg2} alt={listItem.subTitle2} />
          </div>
        ) : null}
        {listItem.panelMultipleListImg ? (
          <div
            className={
              "tabPanelMultipleImgWrapper" +
              (listItem.singleColumn ? " tabPanelMultipleColumnImgWrapper" : "")
            }
          >
            {listItem.panelMultipleListImg.map((imgSrc, index) => (
              <div className="tabPanelMultipleImgList">
                <div className="tabPanelMultipleImg">
                  <img src={imgSrc.img} alt={imgSrc.desc} />
                </div>
                <StyledDesc3 className="p12">{imgSrc.desc}</StyledDesc3>
              </div>
            ))}
          </div>
        ) : null}
      </StyledTabPanelWrapper>
    );
  });
  return (
    <StyledSectionWrapperLightGray id="features">
      <StyledContainerWrapper>
        <StyledFeatureWrapper>
          <div className="positionStickey">
            {title ? <StyledSubTitle1 paddingBottom="pb16">{title}</StyledSubTitle1> : null}
            {desc ? <StyledDesc1 paddingBottom="pb32">{desc}</StyledDesc1> : <Box height="20px" />}
            <StyledTabListWrapper ref={headerRef}>{tab}</StyledTabListWrapper>
          </div>
          <div>{tabPanel}</div>
        </StyledFeatureWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  );
};

export default Features;

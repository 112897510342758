import { COLORS } from "../../globals/designSystem";

const variants = {
  white: {
    fill: COLORS.white,
  },
  black: {
    fill: COLORS.black,
  },
  green: {
    fill: COLORS.green,
  },
  green_80: {
    fill: COLORS.green_80,
  },
  sky_0: {
    fill: COLORS.sky_0,
  },
  sky: {
    fill: COLORS.sky_10,
  },
  sky_80: {
    fill: COLORS.sky_80,
  },
  dark: {
    fill: COLORS.grey_10,
  },
  grey_0: {
    fill: COLORS.grey_0,
  },
  grey: {
    fill: COLORS.grey,
  },
  grey_30: {
    fill: COLORS.grey_30,
  },
  grey_80: {
    fill: COLORS.grey_80,
  },
  grey_84: {
    fill: COLORS.grey_84,
  },
  grey_90: {
    fill: COLORS.grey_90,
  },
  grey_100: {
    fill: COLORS.grey_100,
  },
  blue: {
    fill: COLORS.blue,
  },
  blue_90: {
    fill: COLORS.blue_90,
  },
  blue_50: {
    fill: "#2C64F4",
  },
  blue_400: {
    fill: "#80A3FF",
  },
  red_old: {
    fill: "#de1749",
  },
  red_80: {
    fill: COLORS.red_80,
  },
  yellow_80: {
    fill: COLORS.yellow_80,
  },
  green_40: {
    fill: COLORS.green_40,
  },
  yellow_30: {
    fill: COLORS.yellow_30,
  },
  neutral_4: {
    fill: COLORS.neutral_4,
  },
  neutral_82: {
    fill: COLORS.neutral_82,
  },
  neutral_900: {
    fill: COLORS.neutral_900,
  },
  neutral_1000: {
    fill: COLORS.neutral_1000,
  },
  neutral_500: {
    fill: "#6C737F",
  },
  blue_50: {
    fill: COLORS.blue_50,
  },
  blue_600: {
    fill: COLORS.blue_600,
  },
  neutral_30: {
    fill: COLORS.neutral_30,
  },
  egc_blue: {
    fill: "#1EB4D4",
  },
  blue_600: {
    fill: COLORS.blue_600,
  },
};

const sizes = {
  xs: {
    width: "16px",
    height: "16px",
  },
  sm: {
    width: "18px",
    height: "18px",
  },
  md: {
    width: "24px",
    height: "24px",
  },
  lg: {
    width: "36px",
    height: "36px",
  },
  xl: {
    width: "48px",
    height: "48px",
  },
  xxl: {
    width: "60px",
    height: "60px",
  },
};

const defaults = {
  variant: variants.sky_80,
  size: sizes.sm,
};

export { variants, sizes, defaults };

import styled from "styled-components";
import { mq } from "../../globals/utils";
import {COLORS} from "../../globals/designSystem";
import { transition } from "../shared/CommonStyled";
export const StyledPromoBanner = styled.div`
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: ${COLORS.grey_10};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  .p40 {
    padding: 38px 50px;
    align-self: center;
  }
  .wd50 {
    width: 50%;
  }
  .promoDisplayFlex {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    .divider {
      width: 1px;
      height: 36px;
      background-color: #000000;
      margin: 0 20px;
    }
  }
  .contributeLink {
    display: inline-block;
    text-transform: uppercase;
    a {
      display: flex;
      align-items: center;
      &:hover {
        .arrow {
          transform: translateX(4px);
        }
      }
      .arrow {
        display: flex;
        margin-left: 3px;
        ${transition}
      }
    }
  }
  ul {
    li {
      &:last-child {
        padding-bottom: 0;
      }
      img {
        margin-top: 6px;
        width: 12px !important;
        min-width: 12px !important;
        margin-right: 8px !important;
      }
    }
  }
  .promoImgWrapper {
    display: none;
  }
  ${mq.between("md", "lg")} {
    .wd50 {
      width: 70%;
    }
  }
  ${mq.below.md} {
    background-image: none;
    .p40 {
      padding: 20px;
      padding-bottom: 0;
    }
    .wd50 {
      width: 100%;
    }
    .promoDisplayFlex {
      display: block;
      .divider {
        display: none;
      }
    }
    .promoImgWrapper {
      display: block;
      text-align: right;
      padding-top: 24px;
      img {
        display: inline-block;
        border-radius: 4px;
      }
    }
  }
`;

export default StyledPromoBanner;

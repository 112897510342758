import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { StyledDisplay2, StyledTitleNew2, StyledLead, StyledBody1 } from "../shared/StyledTypography";
import { textCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import securityHeroImg from "./images/security-hero.png";
import fadeBg from "./images/fade-bg.svg";
import walmart from "../homepagenew/images/walmart-color.svg";
import netlify from "../homepagenew/images/netlify-color.svg";
import airbus from "../homepagenew/images/airbus-color.svg";
import atlassian from "../homepagenew/images/atlassian-color.svg";

const StyledSectionWrapperBg = styled(StyledSectionWrapper)`
  background: linear-gradient(180deg, #F4FBFF 7.16%, rgba(255, 255, 255, 0) 100%);
  padding-top: 0;
`;
const StyledHeroBanner = styled.div`
  .heroImg {
    padding: 60px 0 24px 0;
    text-align: center;
    background-image: url(${fadeBg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    img {
      max-height: 310px;
      display: inline-block;
    }
  }
  .securityGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 72px 32px;
  }
  ${mq.between("md", "lg")} {
    .securityGrid {
      grid-template-columns: 2fr 3fr;
    }
  }
  ${mq.below.md} {
    .heroImg {
      padding: 40px 0 24px 0;
    }
    .securityGrid {
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }
  }
`;

const StyledCloudUserWrapper = styled.div`
  padding: 100px 0;
  padding-bottom: 150px;
  display: flex;
  justify-content: space-between;
  .cloudUserImg {
    img {
      max-height: 84px;
    }
  }
  ${mq.between("md", "lg")} {
    padding: 90px 0;
    .cloudUserImg {
      padding: 0 25px;
    }
  }
  ${mq.below.md} {
    padding: 50px 0;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    .cloudUserImg {
      width: 50%;
      padding: 24px 0;
      display: inline-block;
      text-align: left;
      img {
        max-height: 70px;
        display: inline-block;
      }
    }
    .cloudUserImg:nth-of-type(2n) {
      text-align: right;
    }
  }
`;

const TopBanner = () => {
  return (
    <StyledSectionWrapperBg>
      <StyledContainerWrapper>
        <StyledHeroBanner>
          <div className="heroImg">
            <img src={securityHeroImg} alt="Hero banner" />
          </div>
          <StyledDisplay2 paddingBottom="pb16" css={textCenter}>Hasura Cloud Security</StyledDisplay2>
          <StyledLead css={textCenter}>Enterprise grade security in a couple of clicks.</StyledLead>
          <StyledCloudUserWrapper>
            <div className="cloudUserImg">
              <img src={walmart} alt="Walmart" />
            </div>
            <div className="cloudUserImg">
              <img src={netlify} alt="Netlify" />
            </div>
            <div className="cloudUserImg">
              <img src={airbus} alt="Airbus" />
            </div>
            <div className="cloudUserImg">
              <img src={atlassian} alt="Atlassian" />
            </div>
          </StyledCloudUserWrapper>
          <div className="securityGrid">
            <StyledTitleNew2>Our mission</StyledTitleNew2>
            <StyledBody1>
              Hasura Cloud is architected to help companies follow the three tenets of Information Security: <b>confidentiality, integrity, and availability</b>.<br/><br/>
              Hasura Cloud undergoes three layers of security scrutiny. As part of our certification process, we undergo routine testing from external agencies to ensure industry standards are maintained. As an open-source software, our internals are exposed for regular scrutiny from our community to ensure components stay current.<br/><br/>
              To incentivize the community for an extra layer of scrutiny, we also offer a path for responsible disclosure.
            </StyledBody1>
            <StyledTitleNew2>Security requires robust authentication and authorization</StyledTitleNew2>
            <StyledBody1>
              Hasura Cloud provides enterprise grade authorization that can integrate with your authentication provider of choice.<br/><br/>
              Hasura Cloud provides stateless authorization with JWT controls either through authorization headers, cookies, or webhooks. Being built on Postgres, session based control can always be configured but there are a number of good reasons to prefer stateless authentication.
            </StyledBody1>
          </div>
        </StyledHeroBanner>
      </StyledContainerWrapper>
    </StyledSectionWrapperBg>
  );
};

export default TopBanner;
